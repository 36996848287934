<template>
  <div class="mining">
    <q-tabs v-model="tab" @update:model-value="change">
      <q-tab name="1" label="智能理财" />
      <q-tab name="2" label="质押挖矿" />
    </q-tabs>

    <NewRobotGoods v-if="tab == 1" />
    <PledgeGoods v-else-if="tab == 2" />
    <!-- 
    <div class="card q-mt-md">
			<div class="swipe-item" v-for="item in robotList" :key="item.id">
        <div class="card-content">
          <div class="name">{{ item.text }}</div>
            <div class="row justify-between desc">
              <div>
                <div class="text-gray">{{ $t('Mining.rishouyi') }}</div>
                <div>{{ item.shouyi }}% ~ {{ item.jiasu }}%</div>
              </div>
              <div class="text-right">
                <div class="text-gray">{{ $t('Mining.weituojine') }}</div>
                <div>
                  {{ item.amountMin }} ~ {{ item.amountMax }}
                  <span v-if="item.cid == 2">USDT</span>
                  <span v-if="item.cid == 3">USDA</span>
                </div>
              </div>
          </div>
          <div class="btn" @click="onShowBuy(item)">
            <span v-if="item.cid == 2">USDT</span>
            <span v-if="item.cid == 3">USDA</span>
            {{ $t('Mining.weituo') }}
          </div>
        </div>
      </div>
    </div> -->

    <tab-bar active="2" ref="tabbarRef"></tab-bar>
  </div>
</template>

<script>
import TabBar from '@/components/TabBar'
import { ref } from 'vue'
import { Swipe, SwipeItem, Popup } from 'vant'
import NewRobotGoods from '@/views/newrobot/components/NewRobotGoods'
import PledgeGoods from '@/views/pledge/components/PledgeGoods'
import Web3Helper from "../../assets/js/web3helper.js";

export default {
  components: {
    TabBar,
    NewRobotGoods,
    PledgeGoods,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Popup.name]: Popup,
  },
  setup() {
    return {
      robotList: ref([]),
      aRobot: ref([]),
      bRobot: ref([]),
      aRobotIndex: ref(0),
      bRobotIndex: ref(0),
      showRt: ref(false),
      selectRt: ref({}),
      rtjine: ref(""),

      list1: ref([]),
      list2: ref([]),
      list3: ref([]),
      tab: ref("1")
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.getList()
  },
  methods: {
    change(e) {
      this.tab = e
      if (e == 1) this.robotList = this.list1
      if (e == 2) this.robotList = this.list2
      if (e == 3) this.robotList = this.list3
    },
    getimg: function (imgname) {
      let path = this.$config.send_url + "Upload/" + imgname;
      if (typeof imgname == "undefined") {
        path = this.defaulttx;
      }
      return path;
    },
    aRobotChange(index) {
      this.aRobotIndex = index
    },
    bRobotChange(index) {
      this.bRobotIndex = index
    },
    onShowBuy(rt) {
      if (rt.isgm == 0) {
        this.$q.dialog({
          message: this.$t('Mining.jijiangkaifang'),
        });
        return
      }
      this.selectRt = rt
      this.showRt = true
    },
    getList() {
      let _this = this
      _this.$request.post(
        "api/Robot/List",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid
        },
        (res) => {
          if (res.data.code == 0) {
            _this.$q.dialog({
              message: res.data.msg,
            });
          }
          let data = res.data.data
          let language = this.$utils.getloc("language")
          data.forEach(item => {
            if (language == 'zh') {
              item.text = item.name
            } else if (language == 'kor') {
              item.text = item.nameKor
            } else if (language == 'may') {
              item.text = item.nameMay
            } else if (language == 'vie') {
              item.text = item.nameVie
            } else if (language == 'th') {
              item.text = item.nameTh
            } else {
              item.text = item.nameEn
            }
            item.value = item.id
          })
          // console.log(data)
          // _this.aRobot = data.filter(item => item.cid == 2)
          // _this.bRobot = data.filter(item => item.cid == 1)

          // 查询出 level1 level2 level3 的机器人
          _this.list1 = data.filter(item => {
            return item.cid != 1 && item.level == 1
          })
          _this.list2 = data.filter(item => {
            return item.cid != 1 && item.level == 2
          })
          _this.list3 = data.filter(item => {
            return item.cid != 1 && item.level == 3
          })
          _this.robotList = _this.list1
        }
      )
    },
    // 购买机器人
    buyRt() {
      let _this = this
      if (this.rtjine == "") {
        this.$q.dialog({
          message: this.$t('Mining.placeholder1'),
        });
        return
      }
      if (this.rtjine > this.selectRt.amountMax || this.rtjine < this.selectRt.amountMin) {
        this.$q.dialog({
          message: `${this.$t('Mining.placeholder2')}${this.selectRt.amountMin}-${this.selectRt.amountMax}`,
        });
        return
      }
      this.$q.loading.show({
        message: this.$t('Mining.loading')
      })
      let web3Helper = new Web3Helper();
      web3Helper.getSign().then((signStr) => {
        _this.$request.post(
          "api/RobotUser/BuyRt",
          {
            token: _this.$utils.getloc("token"),
            userid: _this.us.userid,
            rid: _this.selectRt.id,
            jine: _this.rtjine,
            signStr: signStr
          },
          (res) => {
            _this.$q.loading.hide();
            _this.$q.dialog({
              message: res.data.msg,
            });
            if (res.data.code == 100) {
              _this.showRt = false
              _this.rtjine = ""
              _this.$refs.orderRef.refresh()
            }
          }
        )
      }).catch(() => {
        _this.$q.loading.hide();
      })
    }
  }
}
</script>

<style scoped>
.mining {
  padding: 10px;
  background-color: #F5F7FA;
}

.card {
  position: relative;
  /* background-color: #fff; */
  /* background-image: url('~@/assets/img/base/arobot.png'); */
  /* background-size: 100% 100%; */
}

.card:nth-child(2) {
  /* background-image: url('~@/assets/img/base/brobot.png'); */
  /* background-size: 100% 100%; */
}

.card-content {
  margin-bottom: 15px;
  position: relative;
  padding: 20px;
  background-image: url('~@/assets/img/base/arobot.png');
  background-size: 100% 100%;
  border-radius: 6px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.name {
  font-size: 24px;
  font-weight: bold;
  color: #1A2648;
}

.desc {
  margin-top: 40px;
  font-size: 12px;
}

.text-gray {
  color: gray;
}

.btn {
  margin: 20px 0 0;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 99rem;
  color: #fff;
  background-color: #2C8B9F;
  font-size: 13px;
}

.indicators {
  margin-top: 20px;
  display: flex;
}

.indicators>div {
  margin-right: 6px;
  background: rgb(36, 96, 215);
  opacity: .1;
  width: 14px;
  height: 6px;
  border-radius: 99rem;
}

.indicators-active {
  opacity: 1 !important;
}

.robot-img {
  position: absolute;
  right: 20px;
  top: 20px;
  width: 80px;
}

.card:nth-child(2) .indicators>div {
  background-color: rgb(88, 0, 200);
}

.card:nth-child(2) .btn {
  background-color: rgb(88, 0, 200);
}

.popup-box {
  padding: 10px;
}

.tr-name {
  padding: 5px 0 20px;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
}

.input-wrap {
  margin-top: 20px;
  padding: 0 16px;
  border-radius: 10px;
  background-color: #f1f1f1;
}

.popup-btn {
  margin-top: 20px;
  text-align: center;
  height: 50px;
  line-height: 50px;
  font-weight: bold;
  border-radius: 10px;
  color: #fff;
  background-color: #2C8B9F;
}
</style>